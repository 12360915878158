import React from 'react'
import ReactBreakpoints from 'react-breakpoints'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1800,
  xxxl: 2000,
}

export const wrapRootElement = ({ element }) => {
  return <ReactBreakpoints breakpoints={breakpoints}>{element}</ReactBreakpoints>
}

// You can delete this file if you're not using it
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
