// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collaborators-js": () => import("./../../../src/pages/collaborators.js" /* webpackChunkName: "component---src-pages-collaborators-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-template-js": () => import("./../../../src/pages/privacyTemplate.js" /* webpackChunkName: "component---src-pages-privacy-template-js" */),
  "component---src-pages-request-received-js": () => import("./../../../src/pages/request-received.js" /* webpackChunkName: "component---src-pages-request-received-js" */),
  "component---src-pages-requestademo-js": () => import("./../../../src/pages/requestademo.js" /* webpackChunkName: "component---src-pages-requestademo-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

